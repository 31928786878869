import { split, HttpLink, ApolloClient, InMemoryCache } from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
// import PusherLink from "./PusherSetup";
import { API_URL } from "../Utils/utils";
import Pusher from "pusher-js";
import PusherLink from "./PusherLink";
import { ApolloLink } from "@apollo/client/core";

// // import Pusher from "pusher-js";
// import { split } from "@apollo/client";
// import { getMainDefinition } from "@apollo/client/utilities";

// const httpLink = createHttpLink({
//   uri: API_URL + "/graphql",
// });
// TODO: @Manish Thakur Need to improvise this code....
const uploadLink = createUploadLink({
  uri: API_URL + "/graphql",
});
const authLink = setContext((_, { headers }) => {
  // if its iframe then no cookie is required...
  if (window.location !== window.parent.location) {
    return {
      headers: {
        ...headers,
      },
    };
  } else {
    // if its not an iframe then cookie is required...
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }
});

// get the authentication token from local storage if it exists
// try {
//   const token = localStorage.getItem("token");
// } catch (e) {
//   const token = null;
// }

// const pusherLink = new PusherLink({
//   pusher: new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
//     cluster: process.env.REACT_APP_PUSHER_CLUSTER,
//     authEndpoint: `${API_URL}/graphql`,
//     auth: {
//       headers: {
//         authorization: token,
//       },
//     },
//   }),
// });
// const uploadComboLink = authLink.concat(uploadLink);
// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     console.log("definition: ", definition);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   pusherLink,
//   authLink
// );
// .concat(uploadLink)
const BEARER_TOKEN = localStorage.getItem("token");

const pusherLink = new PusherLink({
  pusher: new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER, // PUSHER_CLUSTER,
    authEndpoint: `${process.env.REACT_APP_BACKEND_URL}/graphql/subscriptions/auth`, // ${API_LOCATION}
    auth: {
      headers: {
        authorization: `Bearer ${BEARER_TOKEN}`,
      },
    },
  }),
});

const link = ApolloLink.from([authLink, pusherLink, uploadLink]);

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   pusherLink,
//   authLink.concat(uploadLink),
// );

const graphqlClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});
// const graphqlClient = new ApolloClient({
//   uri: API_URL + "/graphql",
//   cache: new InMemoryCache(),
//   headers: {
//     authorization: apiToken ? `Bearer ${apiToken}` : "",
//   },
// });
export default graphqlClient;
